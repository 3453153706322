<template>
  <div>
    <v-btn color="#e21a1a" depressed v-on:click="getOrder" :loading="this.isOrderLoading">
      <v-icon color="white" left>mdi-arrow-right-bottom</v-icon>
      <span class="ReferenceOperatorWorkButtonText">ПРОСМОТРЕТЬ</span>
    </v-btn>
    <v-dialog v-model="dialog" max-width="1280" persistent>
      <v-card v-if="this.isShowWindow" class="file-upload-card" outlined>
        <v-card-title class="pb-4">{{ this.getReferenceName }}</v-card-title>
        <v-container class="OperatorWorkContainer">
          <v-row no-gutters class="RowField" v-for="item in getReferenceFields" :key="item.id">
            <v-col>
              <v-card-title class="VCardTitle">{{ item.name }}</v-card-title>
              <v-text-field
                v-if="item.type === 'fullname'"
                class="custom-label-color"
                outlined
                :label="orderById[item.in]"
                v-model="orderById[item.out]"
                readonly
              ></v-text-field>
              <v-autocomplete
                v-if="item.type === 'departStation'"
                outlined
                v-bind:label="getStationName(orderById.reference[item.in])"
                v-model="orderById.reference[item.out]"
                :items="stationList()"
                item-text="text"
                item-value="text"
                class="custom-label-color"
                readonly
              ></v-autocomplete>
              <v-autocomplete
                v-if="item.type === 'arrivalStation'"
                outlined
                v-bind:label="getStationName(orderById.reference[item.in])"
                v-model="orderById.reference[item.out]"
                :items="stationList()"
                item-text="text"
                item-value="text"
                class="custom-label-color"
                readonly
              ></v-autocomplete>
              <v-text-field
                v-if="item.type === 'trainNumber' && orderById.referenceType !== 5"
                outlined
                :label="orderById.reference[item.in]"
                v-model="orderById.reference[item.out]"
                class="custom-label-color"
                readonly
              ></v-text-field>
              <v-text-field
                v-if="item.type === 'trainNumber' && orderById.referenceType === 5"
                :label="orderById.reference[item.in]"
                outlined
                v-model="orderById.reference[item.out]"
                type="number"
                hide-spin-buttons
                min="0"
                readonly
              ></v-text-field>
              <v-text-field
                v-if="item.type === 'departDate'"
                v-model="visibleDateFromDepartDate"
                :label="visibleDateLabelDepart"
                outlined
                readonly
                prepend-icon="mdi-calendar"
                class="custom-label-color"
              ></v-text-field>
              <v-text-field
                readonly
                v-if="item.type === 'departTime'"
                :label="orderById.reference[item.in]"
                outlined
                v-model="orderById.reference[item.out]"
              ></v-text-field>
              <v-select
                v-if="item.type === 'trainType' && orderById.reference.hasOwnProperty([item.in])"
                :items="trainList()"
                :label="getTrainName(orderById.reference[item.in])"
                outlined
                v-model="orderById.reference[item.out]"
                class="custom-label-color"
                readonly
              ></v-select>
              <v-select
                v-if="item.type === 'trainType' && !orderById.reference.hasOwnProperty([item.in])"
                :items="trainList()"
                outlined
                v-model="orderById.reference[item.out]"
                readonly
              ></v-select>
              <v-select
                v-if="item.type === 'carType' && orderById.reference.hasOwnProperty([item.in])"
                :items="carList()"
                v-bind:label="getCarName(orderById.reference[item.in])"
                outlined
                v-model="orderById.reference[item.out]"
                class="custom-label-color"
                readonly
              ></v-select>
              <v-select
                v-if="item.type === 'carType' && !orderById.reference.hasOwnProperty([item.in])"
                :items="carList()"
                outlined
                v-model="orderById.reference[item.out]"
                readonly
              ></v-select>
              <v-select
                v-if="item.type === 'tariffType' && orderById.reference.hasOwnProperty([item.in])"
                :items="tariffList()"
                :label="orderById.reference[item.in]"
                outlined
                v-model="orderById.reference[item.out]"
                class="custom-label-color"
                readonly
              ></v-select>
              <v-select
                v-if="item.type === 'tariffType' && !orderById.reference.hasOwnProperty([item.in])"
                :items="tariffList()"
                :label="orderById.reference[item.in]"
                outlined
                v-model="orderById.reference[item.out]"
                class="custom-label-color"
                readonly
              ></v-select>
              <v-text-field
                v-if="item.type === 'fullPrice'"
                :label="getExpressFullPrice"
                type="number"
                hide-spin-buttons
                outlined
                v-model="orderById.reference[item.out]"
                class="custom-label-color"
                min="0"
                readonly
              ></v-text-field>
              <v-text-field
                v-if="item.type === 'ticketPrice'"
                :label="getExpressTicketPrice"
                type="number"
                hide-spin-buttons
                outlined
                v-model="orderById.reference[item.out]"
                class="custom-label-color"
                min="0"
                readonly
              ></v-text-field>
              <v-text-field
                v-if="item.type === 'ReservePrice'"
                :label="getExpressReservePrice"
                type="number"
                hide-spin-buttons
                outlined
                v-model="orderById.reference[item.out]"
                class="custom-label-color"
                min="0"
                readonly
              ></v-text-field>
              <v-text-field
                v-if="item.type === 'SerivePrice'"
                :label="getExpressServicePrice"
                type="number"
                hide-spin-buttons
                outlined
                v-model="orderById.reference[item.out]"
                class="custom-label-color"
                min="0"
                readonly
              ></v-text-field>
              <v-text-field
                v-if="item.type === 'CommisionPrice'"
                type="number"
                hide-spin-buttons
                outlined
                v-model="orderById.reference[item.out]"
                min="0"
                readonly
              ></v-text-field>
              <v-select readonly v-if="item.type === 'tickerReturn'" :items="returnList()" outlined v-model="orderById.reference[item.out]"></v-select>
              <v-text-field
                readonly
                v-if="item.type === 'mail'"
                outlined
                :label="orderById[item.in]"
                v-model="orderById[item.out]"
                class="custom-label-color"
              ></v-text-field>
              <v-text-field
                v-if="item.type === 'phone'"
                outlined
                :label="orderById[item.in]"
                v-model="orderById[item.out]"
                class="custom-label-color"
                type="number"
                hide-spin-buttons
                min="0"
                readonly
              ></v-text-field>
              <v-textarea v-if="item.type === 'comment'" rows="1" auto-grow disabled outlined v-model="orderById[item.in]"></v-textarea>
              <v-text-field
                v-if="item.type === 'ticketNumber' && orderById.reference[item.in].length > 0"
                outlined
                :label="orderById.reference[item.in]"
                v-model="orderById.reference[item.out]"
                class="custom-label-color"
                readonly
              ></v-text-field>
              <v-text-field
                v-if="item.type === 'ticketNumber' && orderById.reference[item.in] == ''"
                outlined
                v-model="orderById.reference[item.out]"
                class="custom-label-color"
                readonly
              ></v-text-field>
              <v-text-field v-if="item.type === 'PassengerDocument'" outlined v-model="orderById.reference[item.out]"></v-text-field>
              <v-text-field
                v-if="item.type === 'carNumber'"
                outlined
                v-model="orderById.reference[item.out]"
                type="number"
                hide-spin-buttons
                min="0"
                readonly
              ></v-text-field>
              <v-text-field
                v-if="item.type === 'seatNumber'"
                outlined
                v-model="orderById.reference[item.out]"
                type="number"
                min="0"
                hide-spin-buttons
                readonly
              ></v-text-field>
              <v-text-field
                v-if="item.type === 'seatAviable'"
                :label="orderById.reference[item.in]"
                outlined
                v-model="orderById.reference[item.out]"
                type="number"
                hide-spin-buttons
                min="0"
                readonly
              ></v-text-field>
              <v-select
                v-if="item.type === 'seatType'"
                :items="seatList()"
                :label="getSeatName(orderById.reference[item.in])"
                outlined
                v-model="orderById.reference[item.out]"
                class="custom-label-color"
                readonly
              ></v-select>
              <v-autocomplete
                v-if="item.type === 'changeStation'"
                :disabled="orderById.reference.directMessage"
                outlined
                v-model="orderById.reference[item.out]"
                :items="stationList()"
                item-text="text"
                item-value="text"
                readonly
              ></v-autocomplete>
              <v-text-field v-if="item.type === 'directMessage'" outlined v-model="orderById.reference[item.out]"></v-text-field>
              <v-text-field
                v-if="item.type === 'arrivalDate'"
                :label="visibleLableArrivalDate"
                outlined
                readonly
                v-model="visibleDateFromArrivalDate"
                class="custom-label-color"
              ></v-text-field>
              <v-text-field readonly v-if="item.type === 'arrivalTime'" outlined v-model="orderById.reference[item.out]"></v-text-field>
              <v-text-field v-if="item.type === 'arrivalDateActually'" readonly outlined v-model="visibleDateFromActually"></v-text-field>
              <v-text-field readonly v-if="item.type === 'arrivalTimeActually'" outlined v-model="orderById.reference[item.out]"></v-text-field>
              <v-text-field readonly v-if="item.type === 'token'" outlined v-model="orderById[item.out].token"></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions class="mb-2 d-flex ">
          <v-btn
            v-if="this.orderById.currentState !== 100"
            class="white--text pa-2 ml-auto text-RussianRail"
            depressed
            color="#e21a1a"
            @click="openConfirmDialog"
            >ОТМЕНИТЬ ЗАЯВКУ</v-btn
          >
          <v-btn type="submit" depressed class="mr-3 text-RussianRail" @click="closeDialog">ОТМЕНА</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="cancelReference" max-width="1024" persistent>
      <v-card v-if="this.isShowWindow" class="file-upload-card pa-4" outlined>
        <div>
          Вы действительно хотите отменить заявку? <br />
          Обязанность возврата денег и связь с клиентом ложится на оператора
        </div>
        <v-text-field outlined :label="this.orderById.mail" v-model="cancelEmail"></v-text-field>
        <v-text-field outlined label="Причина" :rules="rules.notEmpty" v-model="cancelReason"></v-text-field>
        <div v-if="this.isCancelError">Не удалось отменить заявку: {{ this.cancelMessage }}</div>
        <v-card-actions class="d-flex flex-row-reverse">
          <v-btn type="submit" depressed class="mr-3 text-RussianRail" v-on:click="closeCancelDialog">Вернуться к заполнению</v-btn>
          <v-btn
            type="submit"
            depressed
            color="#e21a1a"
            :loading="this.isCancelLoading"
            class="white--text mr-3 text-RussianRail"
            v-on:click="confirmCancelReference"
            >Отменить заявку</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ReferenceHelper from '@/helpers/reference/reference.helper';
export default {
  props: {
    order: {
      required: true
    },
    queryParams: {
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      isShowWindow: false,
      cancelReference: false,
      cancelEmail: '',
      cancelReason: '',
      isOrderLoading: false,
      rules: {
        notEmpty: [v => !!v || 0 || 'Обязательное поле']
      }
    };
  },
  computed: {
    getReferenceFields() {
      const fields = ReferenceHelper.getFields();
      if (this.orderById == undefined || this.orderById === null) return '';
      return { ...fields[this.orderById.referenceType] };
    },
    showTrainType() {
      return this.defineTrainType(this.orderById.reference.modifyTrainNumber);
    },
    visibleLableArrivalDate() {
      return this.changeDateForm(this.orderById.reference.departDate);
    },
    visibleDateFromActually() {
      return this.changeDateForm(this.orderById.reference.modifyArrivalDateActually);
    },
    visibleDateFromArrivalDate() {
      return this.changeDateForm(this.orderById.reference.modifyArrivalDate);
    },
    visibleDateFromDepartDate() {
      return this.changeDateForm(this.orderById.reference.modifyDepartDate);
    },
    visibleDateLabelDepart() {
      return this.changeDateForm(this.orderById.reference.departDate);
    },
    getExpressFullPrice() {
      let returnValue = '';
      if (
        this.orderById &&
        this.orderById.reference &&
        this.orderById.reference.expressTravelPrice &&
        this.orderById.reference.expressTravelPrice.tarif !== undefined &&
        this.orderById.reference.expressTravelPrice.tarif !== null
      ) {
        returnValue = this.orderById.reference.expressTravelPrice.tarif;
      }
      return returnValue;
    },
    getExpressTicketPrice() {
      let returnValue = '';
      if (
        this.orderById &&
        this.orderById.reference &&
        this.orderById.reference.expressTravelPrice &&
        this.orderById.reference.expressTravelPrice.tarifbilet !== undefined &&
        this.orderById.reference.expressTravelPrice.tarifbilet !== null
      ) {
        returnValue = this.orderById.reference.expressTravelPrice.tarifbilet;
      }
      return returnValue;
    },
    getExpressReservePrice() {
      let returnValue = '';
      if (
        this.orderById &&
        this.orderById.reference &&
        this.orderById.reference.expressTravelPrice &&
        this.orderById.reference.expressTravelPrice.tarifplac !== undefined &&
        this.orderById.reference.expressTravelPrice.tarifplac !== null
      ) {
        returnValue = this.orderById.reference.expressTravelPrice.tarifplac;
      }
      return returnValue;
    },
    getExpressServicePrice() {
      let returnValue = '';
      if (
        this.orderById &&
        this.orderById.reference &&
        this.orderById.reference.expressTravelPrice &&
        this.orderById.reference.expressTravelPrice.tarifsrv !== undefined &&
        this.orderById.reference.expressTravelPrice.tarifsrv !== null
      ) {
        returnValue = this.orderById.reference.expressTravelPrice.tarifsrv;
      }
      return returnValue;
    },
    getReferenceName() {
      let returnName = 'Справка';
      if (this.order.referenceType === 1) {
        returnName = 'Справка о стоимости проезда';
      } else if (this.order.referenceType === 2) {
        returnName = 'Справка о подтверждении ранее приобретенного (утерянного) проездного документа';
      } else if (this.order.referenceType === 3) {
        returnName = 'Справка о наличии мест в поезде дальнего следования';
      } else if (this.order.referenceType === 4) {
        returnName = 'Справка о проезде в поездах дальнего следования по кратчайшему маршруту следования (на момент обращения)';
      } else if (this.order.referenceType === 5) {
        returnName = 'Справка об изменении графика движения поездов';
      }
      return returnName;
    },
    ...mapGetters('reference', ['isCancelLoading']),
    ...mapGetters('reference', ['isCancelError']),
    ...mapGetters('reference', ['isCancelComplete']),
    ...mapGetters('reference', ['cancelResult']),
    ...mapGetters('reference', ['cancelMessage']),
    ...mapGetters('admin', ['orderById'])
  },
  methods: {
    getSeatName: ReferenceHelper.getSeatName,
    getCarName: ReferenceHelper.getCarName,
    getTariffName: ReferenceHelper.getTariffName,
    getStationName: ReferenceHelper.getStationName,
    getTrainName: ReferenceHelper.getTrainName,
    async getOrder() {
      try {
        this.isOrderLoading = true;
        await this.fetchOrder(this.order.id);
        this.isShowWindow = true;
        this.dialog = true;
      } catch {
        this.isShowWindow = false;
        this.dialog = false;
      } finally {
        this.isOrderLoading = false;
      }
    },
    closeCancelDialog() {
      this.cancelReference = false;
    },
    openConfirmDialog() {
      this.cancelReference = true;
    },
    checkOrder() {
      this.dialog = true;
    },
    defineTrainType(trainNumber) {
      let num = trainNumber.slice(0, 3);
      if ((num >= 1 && num <= 298) || (num >= 801 && num <= 898)) {
        return 'СКОРЫЙ';
      } else if ((num >= 301 && num <= 598) || (num >= 601 && num <= 698)) {
        return 'ПАССАЖИРСКИЙ';
      }
      if (num >= 701 && num <= 750) {
        return 'СКОРОСТНОЙ';
      }
      if ((num >= 751 && num <= 788) || (num >= 601 && num <= 898)) {
        return 'ВЫСОКОСКОРОСТНОЙ';
      } else return '';
    },
    changeDateForm(pickerDate) {
      if (pickerDate === '' || pickerDate === undefined) return '';
      return pickerDate
        .split('-')
        .reverse()
        .join('.');
    },
    stationList() {
      return ReferenceHelper.getStationList();
    },
    tariffList() {
      return ReferenceHelper.getTariffTypeList();
    },
    carList() {
      return ReferenceHelper.getCarTypeList();
    },
    seatList() {
      return ReferenceHelper.getSeatTypeList();
    },
    checkInput() {
      return true;
    },
    trainList() {
      return ReferenceHelper.getTrainTypeList();
    },
    returnList() {
      return ReferenceHelper.getreturnList();
    },
    closeDialog() {
      this.dialog = false;
    },
    async confirmCancelReference() {
      try {
        const data = { orderId: this.orderById.id, message: this.cancelReason, mail: '' };
        if (this.cancelEmail !== undefined && this.cancelEmail !== null && this.cancelEmail.length > 0) {
          data.mail = this.cancelEmail;
        }
        await this.cancelOrder(data);
        this.closeCancelDialog();
        this.closeDialog();
        this.updateReferenceList();
      } catch (error) {
        console.log(error);
      }
    },
    updateReferenceList() {
      this.fetchOrdersForDates({
        queryParams: this.queryParams
      });
    },
    ...mapActions('admin', ['fetchOrdersForDates']),
    ...mapActions('admin', ['fetchOrder']),
    ...mapActions('reference', ['cancelOrder'])
  }
};
</script>
<style>
.RowField {
  padding-left: 15px;
}
.custom-label-color .v-label .theme--light.v-label {
  color: rgb(22, 216, 241);
  opacity: 1;
}
.OperatorWorkContainer > .RowField {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 30%;
}
.OperatorWorkContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.ButtonsGroup {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-end;
}
.CancelButton {
  margin-right: auto;
}

.file-upload-card {
  font-family: RussianRail;
}

.OperatorWorkField {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 20px;
}

.ReferenceOperatorWorkMain {
  font-weight: 400;
}

.ReferenceOperatorWorkButtonIcon {
  margin-right: 10px;
}

.ReferenceOperatorWorkButtonText {
  color: white;
}

.ReferenceOperatorWorkButtonCancelText {
  color: gray;
}

.ReferenceOperatorWorkFieldName {
  display: flex;
  justify-content: center;
  font-size: large;
  margin-bottom: 10px;
  padding: 0;
  color: black;
}

.ReferenceOperatorWorkBlockSpacer {
  margin-top: 20px;
}

.ReferenceOperatorWorkAlertBlock {
  margin-top: 20px;
  color: #c00000;
}

.ReferenceOperatorWorkPanelHeaderWarning {
  font-weight: bold;
  color: #ed7d31;
}

.ReferenceOperatorWorkPanelHeaderError {
  font-weight: bold;
  color: #c00000;
}

.ReferenceOperatorWorkHintText {
  color: #606060;
}

.ReferenceOperatorWorkPicker {
  border-radius: 5px;
  color: white;
  font-weight: 400;
  box-shadow: 0 2px 2px #8eaadb;
  border: solid 1px #2f5496;
  margin: 20px 0 10px 0;
  padding: 2px;
}

.ReferenceOperatorWorkButtonWrapper {
  border: solid 1px #e21a1a;
  border-radius: 6px;
  width: min-content;
}

.ReferenceOperatorWorkButtonCancelWrapper {
  border: solid 1px gray;
  border-radius: 6px;
  margin-left: 20px;
}

.ReferenceOperatorWorkFileText {
  color: black;
}

.ReferenceOperatorWorkCheckResult {
  display: flex;
  //justify-content: space-between;
  margin: 20px 0 0 0;
}

.ReferenceOperatorWorkProcessList {
  display: flex;
  margin: 0 40px 0 0;
}

.ReferenceOperatorWorkProcessListNumber {
  font-size: 42px;
  color: #2f5496;
  line-height: 1;
}

.ReferenceOperatorWorkProcessListText {
  font-size: 14px;
  color: #8eaadb;
  margin-left: 16px;
}

.ReferenceOperatorWorkProcessListNumberTotal {
  font-size: 42px;
  color: black;
  line-height: 1;
}

.ReferenceOperatorWorkProcessListTextTotal {
  font-size: 14px;
  color: #a6a6a6;
  margin-left: 16px;
}

.ReferenceOperatorWorkProcessListNumberWarning {
  font-size: 42px;
  color: #ed7d31;
  line-height: 1;
}

.ReferenceOperatorWorkProcessListTextWarning {
  font-size: 14px;
  color: #f4b083;
  margin-left: 16px;
}

.ReferenceOperatorWorkProcessListNumberError {
  font-size: 42px;
  color: #c00000;
  line-height: 1;
}

.ReferenceOperatorWorkProcessListTextError {
  font-size: 14px;
  color: #ff4b4b;
  margin-left: 16px;
}

.ReferenceOperatorWorkTableRowWarningFirst {
  background-color: #fbe4d5;
  padding: 0 6px 0 6px;
}

.ReferenceOperatorWorkTableRowWarningSecond {
  background-color: white;
  padding: 0 6px 0 6px;
}

.ReferenceOperatorWorkTableRowErrorFirst {
  background-color: #ffc5c5;
  padding: 0 6px 0 6px;
}

.ReferenceOperatorWorkTableRowErrorSecond {
  background-color: white;
  padding: 0 6px 0 6px;
}

.ReferenceOperatorWorkTableHeaderWarning {
  color: black;
  //font-size: small;
  font-weight: bold;
  //background-color: #8ab0d2;
  padding: 0 6px 0 6px;
  //border-radius: 5px;
  border-bottom: solid 1px #ed7d31;
}

.ReferenceOperatorWorkTableHeaderError {
  color: black;
  //font-size: small;
  font-weight: bold;
  //background-color: #8ab0d2;
  padding: 0 6px 0 6px;
  //border-radius: 5px;
  border-bottom: solid 1px #c00000;
}

.VCardTitle {
  font-size: 0.8em !important;
  margin: 0;
  padding: 0;
}

v-col {
  padding: 0;
}
</style>
